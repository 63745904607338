const resourcePath='@/config.js';export default {
    base_url: 'https://api.wemiks.com',
    server_version: '/v1',
    server_v2: '/v2',
    server_v3: '/v3',
    hotnews_server_url: 'https://www.iyuanben.com/server_url',
    miks_oss_bucket_url:'miks.oss-cn-shanghai.aliyuncs.com',
    appname:'miks',
    sentry_dsn:'https://9e74ed86ba434302bc65e1f8891efadf@sentry.yuanben.org/2',
    matrixAliasPostFix:'ybop.wemiks.com'
}
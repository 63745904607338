var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "group-dialog",
          attrs: {
            width: "600px",
            height: "400px",
            title: _vm.title,
            visible: _vm.isSeen
          },
          on: {
            closed: _vm.closed,
            "update:visible": function($event) {
              _vm.isSeen = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.stepOne,
                  expression: "stepOne"
                }
              ],
              staticClass: "step-one zmx-scroll"
            },
            [
              _c(
                "ul",
                _vm._l(_vm.groups, function(item) {
                  return _c(
                    "li",
                    {
                      key: item.id,
                      staticClass: "group-item-row",
                      on: {
                        click: function($event) {
                          return _vm.selectGroup(item)
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.groupFace(
                            item.head_img && item.head_img.storage_url
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(item.name))])
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("scroll-loading", {
                attrs: {
                  "end-text-visible": false,
                  is_empty: !_vm.loading && !_vm.groups.length,
                  "items-length": _vm.groupsPageParams.page_size,
                  type: "scoped"
                },
                on: { load: _vm.loadGroups }
              })
            ],
            1
          ),
          _vm._v(" "),
          !_vm.stepOne
            ? _c("div", { staticClass: "step-two" }, [
                _c(
                  "div",
                  { staticClass: "editor-wrapper" },
                  [
                    _c(
                      "div",
                      {
                        ref: "refEditor",
                        staticClass: "editor empty",
                        attrs: {
                          contenteditable: "true",
                          "data-placeholder": _vm.$t("输入你的看法")
                        },
                        on: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            if (!$event.ctrlKey) {
                              return null
                            }
                            return _vm.post($event)
                          },
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "delete",
                                [8, 46],
                                $event.key,
                                ["Backspace", "Delete", "Del"]
                              )
                            ) {
                              return null
                            }
                            return _vm.del($event)
                          },
                          input: _vm.handle_input,
                          compositionstart: _vm.onCompositionstart,
                          compositionend: _vm.onCompositionend
                        }
                      },
                      [_c("br")]
                    ),
                    _vm._v(" "),
                    _vm.articleDraft.link &&
                    _vm.articleDraft.link.url &&
                    (_vm.articleDraft.article_type === 0 ||
                      _vm.articleDraft.article_type === 2)
                      ? _c("article-link", {
                          attrs: { article: _vm.articleDraft }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "bot" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading"
                        }
                      ],
                      staticClass: "sub-btn",
                      class: { disabled: !_vm.publishReady },
                      attrs: { "element-loading-spinner": "el-icon-loading" },
                      on: { click: _vm.post }
                    },
                    [_vm._v(_vm._s(_vm.$t("发布")))]
                  )
                ])
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
const resourcePath='@/common/function/util.js';import Vue from 'vue'
import router from '@/router'
const createKeccakHash = require('keccak')
// export const extractEmails = (str) => {
//    let emailRegex=/[\w\d_\-\.&\(\)#\~\"\']+@[\w\d_-]+(\.[\w\d_-]+)+/,
//        extractedEmails = [];
//    while(true) {
// 		let breakFlag = true
//       str = str.replace(emailRegex,(v)=>{
// 	     breakFlag = false
//         if(!extractedEmails.includes(v)) {
//             extractedEmails.push(v)
//         }
// 		 return '';
//       })
//       if(breakFlag) {
//          break;
//       }
//    }
//    return {extractedEmails,str};
// }
export const extractEmails = (str) => {
   let emailCandidates = str.split(/\n|\s+|,|;/);
   return emailCandidates.filter(itm=>itm && itm.replace(/\n\s/g,'') != "").map(itm=>itm.trim())
}
//生成随机数
export const getUUID = function (len) {
    len = len || 6;
    len = parseInt(len, 10);
    len = isNaN(len) ? 6 : len;
    var seed = "0123456789abcdefghijklmnopqrstubwxyzABCEDFGHIJKLMNOPQRSTUVWXYZ";
    var seedLen = seed.length - 1;
    var uuid = "";
    while (len--) {
        uuid += seed[Math.round(Math.random() * seedLen)];
    }
    return uuid;
}

//深拷贝
export const deepcopy = function (source) {
    if (!source) {
        return source;
    }
    let sourceCopy = source instanceof Array ? [] : {};
    for (let item in source) {
        sourceCopy[item] = typeof source[item] === 'object' ? deepcopy(source[item]) : source[item];
    }
    return sourceCopy;
}
export const compareVersion = (v1,v2)=>{
   v1 = v1.replace(/[^0-9|\\.]/g,"")
   v2 = v2.replace(/[^0-9|\\.]/g,"")
   let sp1 = v1.split('.').filter((itm)=>itm !== ''),
       sp2 = v2.split('.').filter((itm)=>itm !== '');
   for(let i = 0; i < sp1.length; i ++) {
      if(i >= sp2.length) {
         return 1
      } else if(sp1[i] > sp2[i]) {
         return 1
      } else if(sp1[i] < sp2[i]) {
         return -1
      }
   }
   if(sp2.length > sp1.length)
     return -1
   else
     return 0;
}

//ajax错误处理
export const catchError = function (error) {
    console.log('error')
    if (error.response) {
        if ([609, 610].indexOf(error.response.data.result_code) !== -1) {
            // 登录状态异常
            //sessionkey错误 退出登录
            localStorage.removeItem('session_key')
            localStorage.removeItem('org_id')
            router.push({path: '/login', query: {redirect: router.currentRoute.fullPath}});
            Vue.prototype.$message({
                message: (error.response.data.error_message && error.response.data.error_message.zh) || error.response.data.result_msg || '账号异常，请重新登录',
                type: 'warning'
            });

        } else {
            console.log(error.response);
            // Vue.prototype.$message({
            //     message: (error.response.data.error_message && error.response.data.error_message.zh) || error.response.data.result_msg || '服务端异常，请联系技术支持',
            //     type: 'warning'
            // });
            
            let errText = '';
            let errCode = error.response.data.result_code;

            switch (errCode) {
                case 699:
                    errText = Vue.prototype.$t('未知错误')
                    break;
                case 690:
                    errText = Vue.prototype.$t('服务端异常')
                    break;
                case 600:
                    errText = Vue.prototype.$t('客户端错误')
                    break;
                case 601:
                    errText = Vue.prototype.$t('非法数据')
                    break;
                case 602:
                    errText = Vue.prototype.$t('解析错误')
                    break;
                case 603:
                    errText = Vue.prototype.$t('签名错误')
                    break;
                case 604:
                    errText = Vue.prototype.$t('参数错误')
                    break;
                case 605:
                    errText = Vue.prototype.$t('参数不能为空')
                    break;
                case 606:
                    errText = Vue.prototype.$t('记录未找到')
                    break;
                case 607:
                    errText = Vue.prototype.$t('请先注册')
                    break;
                case 608:
                    errText = Vue.prototype.$t('密码错误')
                    break;
                case 611:
                    errText = Vue.prototype.$t('用户不存在')
                    break;
                case 612:
                    errText = Vue.prototype.$t('邮箱类型不正确')
                    break;
                case 613:
                    errText = Vue.prototype.$t('邮箱格式错误')
                    break;
                case 614:
                    errText = Vue.prototype.$t('权限不足')
                    break;
                case 615:
                    errText = Vue.prototype.$t('成员已存在')
                    break;
                case 616:
                    errText = Vue.prototype.$t('邮件发送成功')
                    break;
                case 617:
                    errText = Vue.prototype.$t('邀请已发送，请勿频繁操作!')
                    break;
                case 618:
                    errText = Vue.prototype.$t('该用户已注册')
                    break;
                case 619:
                    errText = Vue.prototype.$t('成员不存在')
                    break;
                case 620:
                    errText = Vue.prototype.$t('创建小组超过上限')
                    break;
                case 621:
                    errText = Vue.prototype.$t('请验证邮箱')
                    break;
                case 622:
                    errText = Vue.prototype.$t('该手机号已绑定')
                    break;
                case 623:
                    errText = Vue.prototype.$t('该用户为活跃用户')
                    break;
                // case 624:
                //     errText = Vue.prototype.$t('企业已经通过审核')
                //     break;
                // case 625:
                //     errText = Vue.prototype.$t('企业资质审核中……')
                //     break;
                case 626:
                    errText = Vue.prototype.$t('账户已被冻结')
                    break;
                case 627:
                    errText = Vue.prototype.$t('您已举报过此信息')
                    break;
                case 628:
                    errText = Vue.prototype.$t('购买数量不得低于团队人数')
                    break;
                case 629:
                    errText = Vue.prototype.$t('请勿重复购买!')
                    break;
                case 630:
                    errText = Vue.prototype.$t('请勿重复打赏!')
                    break;
                case 631:
                    errText = Vue.prototype.$t('本周5次打赏资格已用完!')
                    break;
                case 632:
                    errText = 'VIP码有误，请核对和重新输入!'
                    break;
                case 633:
                    errText = 'VIP码已失效'
                    break;
                case 634:
                    errText = Vue.prototype.$t('仅管理员可发布!')
                    break;
                case 635:
                    errText = '米粒不足，无法打赏!'
                    break;
                // case 650:
                //     errText = '邀请码无效或已被使用，请重试'
                //     break;
                // case 651:
                //     errText = '请使用邀请码进行注册'
                //     break;
                case 652:
                    errText = Vue.prototype.$t('请先登录!')
                    break;
                case 670:
                    errText = '余额不足!'
                    break;
                case 691:
                    errText = Vue.prototype.$t('访问过于频繁')
                    break;
                case 692:
                    errText = Vue.prototype.$t('发送信息失败，请稍后重试')
                    break;
                case 693:
                    errText = '本节点暂无法注册'
                    break;
                case 694:
                    errText = Vue.prototype.$t('已超过有效期!')
                    break;
                case 701:
                    errText = '该小组人员已达上限，请联系管理员'
                    break;
                case 702:
                    errText = Vue.prototype.$t('小组不存在')
                    break;
                case 703:
                    errText = Vue.prototype.$t('小组已存在')
                    break;
                case 704:
                    errText = Vue.prototype.$t('该小组仅能转让给企业用户')
                    break;
                case 705:
                    errText = Vue.prototype.$t('请先加入小组')
                    break;
                case 706:
                    errText = Vue.prototype.$t('小组已解散')
                    break;
                case 708:
                    errText = Vue.prototype.$t('您已加入其他企业总部小组，不可重复加入！')
                    break;
                case 709:
                    errText = Vue.prototype.$t('转让失败，该成员作为组长的小组数量已达上限。')
                    break;
                case 710:
                    errText = '微信授权失败!'
                    break;
                case 711:
                    errText = '绑定失败，该微信号已存在!'
                    break;
                case 801:
                    errText = '企业已存在!'
                    break;
                case 802:
                    errText = '企业不存在'
                    break;
                case 803:
                    errText = '您已加入其他企业，不可重复加入!'
                    break;
                case 804:
                    errText = '企业人数已达上限，请前往Miks网页端续费!'
                    break;
                default:
                    break;
            }

            Vue.prototype.$message({
                message: errText,
                type: 'warning'
            });
        }

    }
    return Promise.reject(error);
}

//字符三次hash
export const sha256_hash = (str) => {
    let sha1 = createKeccakHash('keccak256').update(str).digest('hex')
    let sha2 = createKeccakHash('keccak256').update(sha1).digest('hex')
    let sha3 = createKeccakHash('keccak256').update(sha2).digest('hex')
    return sha3
}

export const IEVersion = () => {
    let userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    let isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
    let isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
    let isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
    if (isIE) {
        let reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        let fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
            return 7;
        } else if (fIEVersion == 8) {
            return 8;
        } else if (fIEVersion == 9) {
            return 9;
        } else if (fIEVersion == 10) {
            return 10;
        } else {
            return 6;//IE版本<=7
        }
    } else if (isEdge) {
        return 'edge';//edge
    } else if (isIE11) {
        return 11; //IE11
    } else {
        return -1;//不是ie浏览器
    }
}
export const loadScript = (url)=>{
   return new Promise((resolve,reject)=>{
      let script = document.createElement('script');
          script.setAttribute('src',url);
          script.onload = function(){
            resolve()
          }
          document.body.appendChild(script);
   });
}

export const rgbtohsl = (R,G,B)=>{
   let h = 0,
       s = 0,
       l = 0,
       r = R / 255,
       g = G / 255,
       b = B / 255,
       max = Math.max(r,g,b),
       min = Math.min(r,g,b)
   if(max === min) {
      h = 0
   } else if(max === r && g >= b) {
      h = 60 * (g-b)/(max - min)
   } else if(max === r && g < b) {
      h = 60 * (g-b)/(max - min) + 360
   } else if(max === g) {
      h = 60 * (b - r) / (max - min) + 120
   } else if(max === b) {
      h = 60 * (r - g) / (max - min) + 240
   }
   l = 0.5 * (max + min)
   if(l === 0 || h === 0){
      s = 0
   } else if(l < 0.5) {
      s = (max - min) / 2 /l
   } else {
      s = (max - min) / (2 - 2 * l)
   }
   return [h,s,l]
}

export const hsltorgb = (h,s,l)=>{
   if(s === 0)
      return [l * 255,l * 255,l * 255]
   let q = l < 0.5 ? l * (1 + s) : l + s - (l * s),
       p = 2 * l - q,
       hk = h / 360,
       tr = hk + 1/3,
       tg = hk,
       tb = hk - 1/3
       let tarr = [tr,tg,tb].map((item)=>{
          if(item < 0)
            return (item + 1)
          else if(item > 1)
            return (item - 1)
          else 
            return item
       })
       return tarr.map((item)=>{
          if(item < 1/6)
            return Math.round((p + ((q - p) * 6 * item)) * 255)
          else if(item < 0.5) 
            return Math.round(q * 255)
          else if(item < 2/3)
            return Math.round((p + ((q - p) * 6 * (2 / 3 - item))) * 255)
          else 
            return Math.round(p * 255)
       })
}

export function getRGB(text) {
   let regex_1 =
         /^rgba\(\s*(\d+),\s*(\d+),\s*(\d+),\s*\d+\)|rgb\(\s*(\d+),\s*(\d+),\s*(\d+)\)$/,
      regex_2 =
         /^#([0-9|a-f|A-F]{2})([0-9|a-f|A-F]{2})([0-9|a-f|A-F]{2})$/,
      regex_3 = /^#([0-9|a-f|A-F]{1})([0-9|a-f|A-F]{1})([0-9|a-f|A-F]{1})$/
   if (regex_1.test(text)) {
      let res = text.match(regex_1);
      return [res[1], res[2], res[3]];
   } else if (regex_2.test(text)) {
      let res = text.match(regex_2);
      return [
         parseInt(res[1], 16),
         parseInt(res[2], 16),
         parseInt(res[3], 16),
      ];
   } else if(regex_3.test(text)) {
      let res = text.match(regex_3);
      return [
         parseInt(res[1] + res[1], 16),
         parseInt(res[2] + res[2], 16),
         parseInt(res[3] + res[3], 16),
      ];
   } else  {
      return [255, 255, 255];
   }
}

export function chaincall(fn) {
   let args = [];
   const result =  {
      call:(arg)=>{args.push(arg);return result},
      finalize:()=>{return fn.apply(this,args)}
   }
   return result;
}

export function currylize (fn) {
   let args = Array.prototype.slice.call(arguments, 1)
   return function() {
       var newArgs = args.concat(Array.prototype.slice.call(arguments));
       if(newArgs.length < fn.length)
         return currylize(fn,...newArgs)
       else
         return fn.apply(this, newArgs)
   }
}

export function isFirefox(){
   return navigator.userAgent.indexOf("Firefox")>0
}

export function getEventOffsetX(event){
   return isFirefox() ? event.layerX : event.offsetX
}

export function getEventOffsetY(event){
   return isFirefox() ? event.layerY : event.offsetY
}

export function getInnerMostElement(elements,parent) {
   let elementsMap = new Map(),
       elementsList = elements.filter((itm)=>{
      let curItm = itm;
      while(curItm) {
         if(curItm === parent) {
            elementsMap.set(itm,true)
            return true
         }
         curItm = curItm.parentElement
      }
      return false;
   });
   elementsList.forEach((itm)=>{
      let curItm = itm.parentElement
      do {
         if(elementsMap.has(curItm)) {
            elementsMap.set(curItm,false)
         }
         curItm = curItm.parentElement
      }while(curItm !== parent && curItm)
   })
   let ret = null
   elementsMap.forEach((v,k)=>{
      if(v) {
         ret = k
         return true
      }
   })
   return ret;
}

export const isFolder = (dropFile) => {
   return new Promise((resolve,reject)=>{
      let fileReader = new FileReader();
          fileReader.addEventListener('load', function (e) {
               console.log(e, 'load');
               resolve(false)
           }, false);
          fileReader.addEventListener('error', function (e) {
               resolve(true)
          }, false);
          fileReader.readAsDataURL(dropFile.slice(0, 1));
   });
}

export const isChildNode = (node1, node2) => {
   let node = node2;
   while (node) {
      if (node1 === node) return true;
      node = node.parentNode;
   }
   return false;
};
export const noop = ()=>{}

export const isWechatBrowser = () => {
    let ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
    } else {
        return  false;
    }
};
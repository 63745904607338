const resourcePath='@/store/actions.js';import NodesAPI from '@/api/v3/nodes'
import GroupAPI from '@/api/v3/group'

import API_group from '@/api/group'
import API_account from '@/api/account'
import API_news from '@/api/news'
import Axios from '@/axios.config.js'
import {compareVersion} from '@/common/function/util.js'
const actions = {
    update_myGroups({commit, state}, params = {page: 0, page_size: 20}) {
        return new Promise((resolve, reject) => {
            Axios.get(API_group.common_url, {params})
                .then((res) => {
                    let myGroups = res.data.data
                    commit('replace_myGroups', {myGroups, page: params.page})
                    resolve({myGroups, page: params.page})  //用于return返回用
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    get_user({commit, state, dispatch}, cb) {
        Axios.get(API_account.user.info)
            .then((res) => {
                if (res) {
                    let user = res.data.data;
                    commit('updateUser', user);
                    dispatch("users/setAppUser", user);

                    cb && cb(user);
                } else {
                    cb && cb(null);
                }
            })
    },

    // 获取节点列表
    get_nodes({commit, state}) {
        NodesAPI.getServices()
            .then(res => {
                commit('get_nodes', res.datas)
            })
    },

    // 火眼新闻
    getNewsTags({commit}) {
      return Axios.get(API_news.tags, {baseURL: '/'})
        .then(res => {
          commit('addNewsTags', [{id:0,name:'全部'}, ...res.data.data])
        })
    },

    // 小组基本信息
    getGroupInfo({commit, state}, gid) {
        commit('updateGroupInfo', {latest_id: gid})

        return new Promise((resolve, reject) => {
            Axios.get(API_group.group(gid))
                .then(res => {
                    let data = res.data.data
                    if (data.group_id == state.currentGroup.latest_id) {
                        commit('updateGroupInfo', {data})
                        resolve(data)
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },

    // 小组成员列表
    getGroupMember({commit, state}, params) {
        let {group_id, ...pageParams} = params

        return new Promise((resolve, reject) => {
            Axios.get(API_group.users(group_id), {params: pageParams})
                .then(res => {
                    let list = res.data.data || []
    
                    pageParams.page++
                    pageParams.page_size = list.length > 20 ? 20 : list.length
    
                    if (state.currentGroup.latest_id && group_id == state.currentGroup.latest_id) {
                        commit('updateGroupMember', {pageParams, list})
                    } else {
                        commit('updateGroupMember', {pageParams, list})
                    }

                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        })

        // let res = getMemberApi()
    },

    // 我的小组
    getGroups({commit, state}, pageParams) {
        return new Promise((resolve, reject) => {
            Axios.get(API_group.common_url, {params: pageParams})
                .then(res => {
                    let list = res.data.data || []

                    pageParams.page++
                    pageParams.page_size = list.length > 20 ? 20 : list.length

                    commit('updateGroups', {pageParams, list})

                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        })
    },


    // 小组dashboard一起写、文件
    groupDashboard({commit, state}, gid) {
        GroupAPI.getFilesAndDocs(gid)
            .then(res => {
                commit('groupDashboard', res)
            })
    },
    updateAppVersionInfo({commit,state},appVInfo) {
        commit('appVersionInfo',appVInfo)
        let oldVersion = APP_VERSION || '0.0.0'
        commit('setOutDated',compareVersion(state.appVersionInfo.version,oldVersion) > 0)
    }
}

export default actions

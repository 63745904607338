const resourcePath='@/components/face.vue';//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isWechatBrowser } from "@/common/function/util";
import config from '@/config.js'
import {mapState} from "vuex";

export default {
    name: '',
    props: {
        author: {
            type: Object,
            required: true,
            default: {
                name: 'X',
                out_uid: '',
                head_img: null
                // head_img: {
                //     storage_url: ''
                // },
            }
        },
        type: {
            type: String,
            default: 'circle'
        },
        size: {
            type: String,
            default: 'normal'
        },
        link: {
            type: Boolean,
            default: true
        }
    },
    components: {

    },
    computed: {
        ...mapState([
            'user',
        ]),
        faceUrl() {
            return this.author.head_img.storage_url + '?x-oss-process=image/resize,m_fill,w_80,h_80,limit_0'
        },
        isMicroMessenger() {
            return isWechatBrowser();
        }
    },
    data() {
        return {}
    },
    methods: {
        clickAvatar() {
            if (this.user.out_uid) {
                this.$router.push({path:`/account/personal`,query:{user_id:author.out_uid}});
            } else {
                this.loginWechat();
            }
        },
        loginWechat() {
            console.log('loginWechat');
            let origin = window.location.protocol + '//' + window.location.hostname;
            console.log('[debug] origin: ' + origin);
            let redirect = this.$router.currentRoute.fullPath;
            if (this.isMicroMessenger) {
                let wxRedirectUri = `${config.base_url}/v1/account/wx/login`;
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa2943926a4ead040&redirect_uri=${wxRedirectUri}&response_type=code&scope=snsapi_userinfo&state=${origin}${redirect}#wechat_redirect`;
            } else {
                this.$router.push({path:`/account/personal`,query:{user_id:this.author.out_uid}});
            }
        },
    }
}

const resourcePath='@/store/modules/appbanner.js';const state = {
   registed:{},
   triggered:{},
   headerHeight: '56px'
};

const getters = {
   
};

const actions = {
};

const mutations = {
   registAppBanner (state,banner) {
      Object.assign(state.registed,banner);
   },
   triggerBanner (state,payload) {
       state.headerHeight = '104px'
       state.triggered[payload.name] = payload
   },
   setHeaderHeight(state,payload) {
      state.headerHeight = payload
   },
   removeBanner (state) {
       state.headerHeight = '56px'
      delete state.triggered[payload.name]
   }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.author
    ? _c(
        "div",
        {
          on: {
            click: function($event) {
              $event.stopPropagation()
            }
          }
        },
        [
          _vm.link && _vm.isMicroMessenger
            ? _c(
                "a",
                {
                  staticClass: "face",
                  class: [
                    _vm.type,
                    _vm.size,
                    { default: !_vm.author.head_img }
                  ],
                  on: { click: _vm.clickAvatar }
                },
                [
                  _vm.author.head_img && _vm.author.head_img.storage_url
                    ? _c("img", {
                        staticClass: "face-img",
                        attrs: { src: _vm.faceUrl, alt: "" }
                      })
                    : _c("span", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.author.name &&
                              _vm.author.name.toUpperCase().split("")[0]
                          )
                        )
                      ])
                ]
              )
            : _vm.link
            ? _c(
                "router-link",
                {
                  staticClass: "face",
                  class: [
                    _vm.type,
                    _vm.size,
                    { default: !_vm.author.head_img }
                  ],
                  attrs: {
                    to: {
                      path: "/account/personal",
                      query: { user_id: _vm.author.out_uid }
                    }
                  }
                },
                [
                  _vm.author.head_img && _vm.author.head_img.storage_url
                    ? _c("img", {
                        staticClass: "face-img",
                        attrs: { src: _vm.faceUrl, alt: "" }
                      })
                    : _c("span", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.author.name &&
                              _vm.author.name.toUpperCase().split("")[0]
                          )
                        )
                      ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.link
            ? _c(
                "div",
                {
                  staticClass: "face",
                  class: [_vm.type, _vm.size, { default: !_vm.author.head_img }]
                },
                [
                  _vm.author.head_img && _vm.author.head_img.storage_url
                    ? _c("img", {
                        staticClass: "face-img",
                        attrs: { src: _vm.faceUrl, alt: "" }
                      })
                    : _c("span", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.author.name &&
                              _vm.author.name.toUpperCase().split("")[0]
                          )
                        )
                      ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }